import { useCallback } from 'react';

import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import axios from 'axios';
import qs from 'qs';

// const SURVEY_ID = 1;

const surveyJson = {
  title: "5G宜蘭好康第一站",
  logoPosition: "right",
  focusFirstQuestionAutomatic: false,
  showQuestionNumbers: "off",
  checkErrorsMode: "onNextPage",
  completeText: "完成",
  pagePrevText: "上一頁",
  pageNextText: "下一頁",
  widthMode: "responsive",
  completedHtml: '<h3>加入LINE@官方帳號<br/>領取數位優惠券</h3><div align="center"><a href="https://lin.ee/CpM7W2U"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" alt="加入好友" height="50" border="0"></a></div><div style="height:100px;"></div>',
  questionErrorLocation: "bottom",
  showProgressBar: "top",
  showPrevButton: true,
  //showPreviewBeforeComplete: "showAnsweredQuestions",
  pages: [{
    elements: [{
      name: "Q1",
      title: "請問您是第一次造訪5G宜蘭好康第一站智販機嗎？",
      type: "radiogroup",
      choices: [
        { value: 1, text: "是" },
        { value: 2, text: "否" }
      ],
      isRequired: true
    }]
  }, {
    elements: [{
      name: "Q2",
      title: "請問您是第一次造訪宜蘭嗎？",
      type: "radiogroup",
      choices: [
        { value: 1, text: "是" },
        { value: 2, text: "否" },
        { value: 3, text: "我是宜蘭人" }
      ],
      isRequired: true
    }]
  }, {
    elements: [{
      name: "Q3",
      title: "請問您平均造訪宜蘭的頻率是？",
      type: "radiogroup",
      choices: [
        { value: 1, text: "每日" },
        { value: 2, text: "每週1次以上" },
        { value: 3, text: "每月1次以上" },
        { value: 4, text: "半年1次以上" },
        { value: 5, text: "每年1次以上" },
        { value: 6, text: "其他" }
      ],
      isRequired: true
    }]
  }, {
    elements: [{
      name: "Q4",
      title: "請問您對於本次5G宜蘭好康第一站智販機是否感到滿意？",
      type: "radiogroup",
      choices: [
        { value: 5, text: "非常滿意" },
        { value: 4, text: "滿意" },
        { value: 3, text: "普通" },
        { value: 2, text: "不滿意" },
        { value: 1, text: "非常不滿意" }
      ],
      isRequired: true
    }]
  }, {
    elements: [{
      type: "panel",
      name: "contact",
      title: "請留下基本資料，領取數位優惠券",
      elements: [{
        type: "text",
        name: "userName",
        title: "姓名",
        isRequired: false
      },
      {
        type: "text",
        name: "userMobile",
        title: "手機",
        isRequired: false
      }],
    }],
  }],
};

function App() {
  const survey = new Model(surveyJson);
  const alertResults = useCallback(async(sender) => {
    const surveyJsonStr = JSON.stringify(sender.data);
    //alert(surveyJsonStr);
    const surveyJsonB64 = btoa(unescape(encodeURIComponent(surveyJsonStr)));
    //console.log('surveyJsonB64', surveyJsonB64);

    const surveyJson = decodeURIComponent(escape(atob(surveyJsonB64)));
    //console.log('surveyJson', surveyJson);

    const formData = {
      accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      rawdata: surveyJsonB64,
    }
    const uri = process.env.REACT_APP_BACKEND_URL + '/api/add/survey';
    //console.log('uri', uri, 'formData', formData);

    const info = {};
    await axios.post(uri, qs.stringify(formData), {})
      .then(function (response) {
        console.log('response', response);
        info.result = response.result;
        info.msg = response.msg;
        info.qrcode = response.qrcode;
      })
      .catch(function (error) {
        console.log('err message', error);
        //res.status(500).send(error);
      });


    // saveSurveyResults(
    //   "https://your-web-service.com/" + SURVEY_ID,
    //   sender.data
    // )
  }, []);

  survey.onComplete.add(alertResults);

  return <Survey model={survey} />;
}
/*
    <div align="center">
      <img src="https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=5d4204df4545df31ad5132dbb54149f9" />
    </div>
*/
// function saveSurveyResults(url, json) {
//   fetch(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     body: JSON.stringify(json)
//   })
//   .then(response => {
//     if (response.ok) {
//       // Handle success
//     } else {
//       // Handle error
//     }
//   })
//   .catch(error => {
//     // Handle error
//   });
// }

export default App;
